.footer {
    display: flex;
    justify-content: space-around;  /* Changed from space-between to space-around */
    align-items: center;
    padding: 60px 20px;  /* Increased padding */
    background-color: #2d251a;
    color: #ffffff;
  }
  
  .footer div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-left,
  .footer-right {
    margin: 0 50px;  /* Adding margin to bring text inward */
  }
  
  .footer-center {
    display: flex;
    flex-direction: row !important;  /* Ensure it's row, not column */
    align-items: center;  /* Center alignment vertically */
    justify-content: center;  /* Center alignment horizontally */
  }
  
  .footer-center a {
    margin: 0 5px;   /* Adjust the margin as needed for spacing */
    color: #333;
    font-size: 24px;
  }
  
  .footer-center a:hover {
    color: #555;
  }
  
  